const Race = [
  "African American/Black/Afro-Caribbean/African",
  "American Indian / Alaska Native",
  "Native Hawaiian / Pacific Islander",
  "Asian / Asian American",
  "Middle Eastern / Arab American",
  "White / White American",
  "Prefer not to answer",
  "Other",
];

export default Race;
