const specialties = [
  { label: "notApplicable", value: "N/A If Not Applicable" },
  { label: "allergyImmunology", value: "Allergy & Immunology" },
  { label: "anesthesiology", value: "Anesthesiology" },
  { label: "cardiology", value: "Cardiology" },
  { label: "criticalCareMedicine", value: "Critical Care Medicine" },
  { label: "dermatology", value: "Dermatology" },
  { label: "diagnostic", value: "Diagnostic" },
  { label: "emergencyMedicine", value: "Emergency Medicine" },
  { label: "endocrinology", value: "Endocrinology" },
  { label: "familyMedicine", value: "Family Medicine" },
  { label: "gastroenterology", value: "Gastroenterology" },
  { label: "geriatrics", value: "Geriatrics" },
  { label: "headNeck", value: "Head & Neck" },
  { label: "hematologyOncology", value: "Hematology & Oncology" },
  { label: "infectiousDisease", value: "Infectious Disease" },
  { label: "internalMedicine", value: "Internal Medicine" },
  { label: "interventional", value: "Interventional" },
  { label: "medicalGeneticsGenomics", value: "Medical Genetics & Genomics" },
  { label: "nephrology", value: "Nephrology" },
  { label: "neurology", value: "Neurology" },
  { label: "Nuclear Medicine", value: "Nuclear Medicine" },
  { label: "obstetricsGynecology", value: "Obstetrics & Gynecology" },
  { label: "oncology", value: "Oncology" },
  { label: "ophthalmology", value: "Ophthalmology" },
  { label: "otolaryngology", value: "Otolaryngology" },
  { label: "painMedicine", value: "Pain Medicine" },
  { label: "palliativeCare", value: "Palliative Care" },
  { label: "pathology", value: "Pathology" },
  { label: "pediatrics", value: "Pediatrics" },
  {
    label: "physicalMedicineRehabilitation",
    value: "Physical Medicine & Rehabilitation",
  },
  { label: "preventiveMedicine", value: "Preventive Medicine" },
  { label: "psychiatry", value: "Psychiatry" },
  { label: "radiation", value: "Radiation" },
  { label: "radiology", value: "Radiology" },
  { label: "rheumatology", value: "Rheumatology" },
  { label: "sleepMedicine", value: "Sleep Medicine" },
  { label: "sportsMedicine", value: "Sports Medicine" },
  { label: "surgeryGeneral", value: "Surgery, General" },
  { label: "surgeryNeurologic", value: "Surgery, Neurologic" },
  { label: "surgeryOphthalmic", value: "Surgery, Ophthalmic" },
  { label: "surgeryOrthopedic", value: "Surgery, Orthopedic" },
  { label: "surgeryOtolaryngology", value: "Surgery, Otolaryngology" },
  { label: "surgeryPediatric", value: "Surgery, Pediatric" },
  { label: "surgeryPlastic", value: "Surgery, Plastic" },
  { label: "surgeryThoracic", value: "Surgery, Thoracic" },
  { label: "surgeryVascular", value: "Surgery, Vascular" },
  { label: "toxicology", value: "Toxicology" },
  { label: "urology", value: "Urology" },
  { label: "womensHealth", value: "Women's Health" },
  { label: "radiology", value: "Radiology" },
];

export default specialties;
