const GenderIdentity = [
  "Female",
  "Male",
  "Non-binary",
  "Gender Queer",
  "Prefer not to answer",
  "Self-describe as:",
];

export default GenderIdentity;
