import React from "react";
import doctor from "../images/blackfemaledoctorsconfident.jpg";
import "./construction.css";


const Team = () => {
  return (
    <div className="construction-body">
      <img className="doctor-img" src={doctor} alt="" />
      <p>Page Coming Soon</p>
    </div>
  );
};

export default Team;
